import PropTypes from 'prop-types';
import React from 'react';

import { ContentLayout } from '../..';
import styles from './sustainabilityFooter.module.scss';

export const SustainabilityFooter = ({ footerData }) => {
	return (
		<ContentLayout>
			<div className={styles.sustainabilityFooter}>
				<a target="_blank" rel="noreferrer" href={footerData.sustainabilityReportLink}>
					{footerData.sustainabilityReportText}
				</a>
			</div>
			<div className={styles.sustainabilityFooter}>
				<a target="_blank" rel="noreferrer" href={`https:${footerData.lifeCycleAssessmentFile}`}>
					{footerData.lifeCycleAssessmentText}
				</a>
			</div>
			<div className={styles.sustainabilityFooter}>
				<a target="_blank" rel="noreferrer" href={`https:${footerData.ethicalPolicyLink}`}>
					{footerData.ethicalPolicyText}
				</a>
			</div>
			<div className={styles.sustainabilityFooter}>
				<a target="_blank" rel="noreferrer" href={`https:${footerData.codeOfEthicsLink}`}>
					{footerData.codeOfEthicsText}
				</a>
			</div>
		</ContentLayout>
	);
};

SustainabilityFooter.propTypes = {
	footerData: PropTypes.shape({
		sustainabilityReportText: PropTypes.string.isRequired,
		sustainabilityReportLink: PropTypes.string.isRequired,
		lifeCycleAssessmentText: PropTypes.string.isRequired,
		lifeCycleAssessmentFile: PropTypes.string.isRequired,
		ethicalPolicyText: PropTypes.string.isRequired,
		ethicalPolicyLink: PropTypes.string.isRequired,
		codeOfEthicsText: PropTypes.string.isRequired,
		codeOfEthicsLink: PropTypes.string.isRequired,
	}),
};
