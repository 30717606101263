import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import { ContentLayout } from '../..';
import styles from './SustainabilityHeader.module.scss';

export const SustainabilityHeader = ({ headerData }) => {
	const link = (
		<a target="_blank" rel="noreferrer" href={headerData.sustainabilityReportLink}>
			{headerData.sustainabilityReportText}
		</a>
	);
	const desktopFluid = headerData.mobileBackgroundImage.fluid;
	const mobileFluid = headerData.desktopBackgroundImage.fluid;
	return (
		<>
			<div className={styles.container}>
				<div className={styles.mobileImgWrapper}>{desktopFluid && <Img fluid={desktopFluid} />}</div>
				<div className={styles.desktopImgWrapper}>{mobileFluid && <Img fluid={mobileFluid} />}</div>
				<ContentLayout className={styles.headerContent}>
					<div className={styles.card}>
						<blockquote>{headerData.quote}</blockquote>
						<p>{headerData.author}</p>
						{link}
					</div>
				</ContentLayout>
			</div>
			<ContentLayout>
				<div className={styles.downloadLinkWrapper}>{link}</div>
			</ContentLayout>
		</>
	);
};

SustainabilityHeader.propTypes = {
	headerData: PropTypes.shape({
		quote: PropTypes.string.isRequired,
		author: PropTypes.string.isRequired,
		mobileBackgroundImage: PropTypes.shape({
			fluid: PropTypes.object,
		}).isRequired,
		desktopBackgroundImage: PropTypes.shape({
			fluid: PropTypes.object,
		}).isRequired,
		sustainabilityReportText: PropTypes.string.isRequired,
		sustainabilityReportLink: PropTypes.string.isRequired,
	}).isRequired,
};
