import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { useEffect } from 'react';

import { QuickBottomNavigationWrapper } from '../../components';
import Layout from '../../components/layout';
import {
	OurCommunity,
	OurPeople,
	OurPlanet,
	OurStandards,
	OurWhistleblowerSystem,
	SustainabilityFooter,
	SustainabilityHeader,
} from '../../components/Pages/Sustainability';
import SEO from '../../components/seo';
import { QUICK_NAVIGATION_SUSTAINABILITY, getLocalizedDataFromContentfulEdges } from '../../utils';

const ESG = () => {
	const intl = useIntl();
	const location = useLocation();
	const { anchorData } = location.state || {};
	useEffect(() => {
		const sections = document.querySelectorAll('[data-anchor]');
		if (anchorData) {
			let targetSection = null;
			sections.forEach(el => {
				if (el.dataset.anchor === anchorData) {
					targetSection = el;
				}
			});
			if (targetSection !== null) {
				window.scrollTo({ top: targetSection.offsetTop - 64, behavior: 'smooth' });
			}
		}
	}, [anchorData]);
	const data = useStaticQuery(graphql`
		{
			allContentfulSpecificPageMetaData(filter: { pageKey: { eq: "sustainability" } }) {
				edges {
					node {
						pageKey
						title
						description
						node_locale
						previewImage {
							fixed(width: 1200, height: 630) {
								src
							}
						}
					}
				}
			}
			allContentfulSustainabilityHeader {
				edges {
					node {
						node_locale
						quote
						mobileBackgroundImage {
							fluid(maxWidth: 860, quality: 100) {
								...GatsbyContentfulFluid
							}
						}
						desktopBackgroundImage {
							fluid(maxWidth: 1920, quality: 100) {
								...GatsbyContentfulFluid
							}
						}
						id
						author
						sustainabilityReportText
						sustainabilityReportLink
						lifeCycleAssessmentText
						lifeCycleAssessmentFile {
							file {
								url
							}
						}
						codeOfEthicsText
						codeOfEthicsFile {
							file {
								url
							}
						}
						ethicalTradingPolicyText
						ethicalTradingPolicyFile {
							file {
								url
							}
						}
					}
				}
			}
		}
	`);
	const { node: headerData } = getLocalizedDataFromContentfulEdges(
		data.allContentfulSustainabilityHeader.edges,
		intl.locale
	)[0];
	const footerData = {
		sustainabilityReportText: headerData.sustainabilityReportText,
		sustainabilityReportLink: headerData.sustainabilityReportLink,
		lifeCycleAssessmentText: headerData.lifeCycleAssessmentText,
		lifeCycleAssessmentFile: headerData.lifeCycleAssessmentFile.file.url,
		ethicalPolicyText: headerData.ethicalTradingPolicyText,
		ethicalPolicyLink: headerData.ethicalTradingPolicyFile.file.url,
		codeOfEthicsText: headerData.codeOfEthicsText,
		codeOfEthicsLink: headerData.codeOfEthicsFile.file.url,
	};
	const { node } = getLocalizedDataFromContentfulEdges(data.allContentfulSpecificPageMetaData.edges, intl.locale)[0];
	return (
		<>
			<SEO lang={intl.locale} title={node.title} description={node.description}>
				<meta property="og:image" name="image" content={`http:${node.previewImage.fixed.src}`} />
			</SEO>
			<Layout>
				<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_SUSTAINABILITY}>
					<SustainabilityHeader headerData={headerData} />
					<OurPlanet />
					<OurPeople />
					<OurCommunity />
					<OurStandards />
					<OurWhistleblowerSystem />
					<SustainabilityFooter footerData={footerData} />
				</QuickBottomNavigationWrapper>
			</Layout>
		</>
	);
};

export default ESG;
