import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { getLocalizedDataFromContentfulEdges } from '../../../utils';
import { SustainabilitySection } from './';

export const OurPeople = () => {
	const intl = useIntl();
	const data = useStaticQuery(graphql`
		{
			allContentfulOurPeople {
				edges {
					node {
						node_locale
						header
						description {
							description
						}
						background {
							fluid(maxWidth: 1920, quality: 100) {
								aspectRatio
								src
								srcSet
								sizes
							}
						}
						paragraphs {
							header
							content {
								raw
							}
						}
					}
				}
			}
		}
	`);
	const { node } = getLocalizedDataFromContentfulEdges(data.allContentfulOurPeople.edges, intl.locale)[0];

	return <SustainabilitySection data={node} theme="people" />;
};
