import cx from 'classnames';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import CollapseIcon from '../../../assets/icons/double-chevron.svg';
import RichTextRenderer from '../../RichTextRenderer';
import { Collapse, ContentLayout } from '../..';
import styles from './sustainability.module.scss';

export const SustainabilitySection = ({ data, theme }) => {
	const [isOpen, setIsOpen] = useState(true);
	const { header, description, background, paragraphs } = data;

	const renderParagraph = (paragraph, index) => {
		const { header, content } = paragraph;
		const paragraphBody = (
			<>
				<div className={cx(styles.text)}>{RichTextRenderer(content)}</div>
			</>
		);
		return (
			<li key={`sustainability-paragraph-${header}-${index}`} className={cx(styles.paragraph, isOpen && styles.isOpen)}>
				<div className={styles.desktopContentWrapper}>
					<h3 className={cx(styles.paragraphHeader, isOpen && styles.isOpen)}>{header}</h3>
					<div className={cx(styles.content, styles.collapsableContent, isOpen && styles.isOpen)}>{paragraphBody}</div>
					<div className={cx(styles.dynamicBorder, styles[theme])} />
					<div className={cx(styles.staticBorder, styles[theme])} />
				</div>
				<div className={cx(styles.mobileContentWrapper, styles[theme])}>
					<h3 className={styles.paragraphHeader}>{header}</h3>
					<Collapse contentClassName={styles.content} content={paragraphBody} />
				</div>
			</li>
		);
	};

	return (
		<section id={theme} data-anchor={theme}>
			<div className={styles.headerWrapper}>
				<ContentLayout>
					<h2 className={cx(styles.header, styles[theme])}>{header}</h2>
					<p className={styles.description}>{description.description}</p>
				</ContentLayout>
			</div>
			{background.fluid && <Img fluid={background.fluid} className={styles.mobileImage} />}
			<div
				className={cx(styles.contentWrapper, isOpen && styles.isOpen)}
				style={{ backgroundImage: `url('https:${background.fluid && background.fluid.src}')` }}>
				<ContentLayout>
					<div className={styles.content}>
						<ul className={styles.paragraphs}>{paragraphs.map(renderParagraph)}</ul>
						<button
							className={cx(
								styles.collapseButton,
								styles.desktopCollapseButton,
								styles[theme],
								isOpen && styles.buttonIsOpen
							)}
							onClick={() => setIsOpen(!isOpen)}>
							<CollapseIcon />
						</button>
					</div>
				</ContentLayout>
			</div>
		</section>
	);
};

SustainabilitySection.propTypes = {
	data: PropTypes.shape({
		header: PropTypes.string.isRequired,
		description: PropTypes.shape({
			description: PropTypes.string.isRequired,
		}).isRequired,
		background: PropTypes.shape({
			fluid: PropTypes.shape({
				src: PropTypes.string,
			}),
		}).isRequired,
		paragraphs: PropTypes.arrayOf(
			PropTypes.shape({
				header: PropTypes.string.isRequired,
				content: PropTypes.shape({
					raw: PropTypes.string,
				}).isRequired,
			})
		),
	}).isRequired,
	theme: PropTypes.string,
};
